import { AxiosResponse } from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import AuthService from '@/services/auth.service'
import { IRegisterUser, IUserLogin } from '@/types/general'
import { rootUrl } from '@/utils/helpers/urlHelpers'

interface IState {
  user: Record<string, unknown> | null
  roles: Array<string> // Is this correct??
  accounts: Array<string>

  // not necessary?
  //cRole: string
  //cParticipant: IParticipant ???
  //cAccountIndex: Integer ???
  //uAccounts: json ???
}

// TODO: Should this be getCurrentUser instead of getUser?
const initialState: IState = { user: AuthService.getUser(), roles: [], accounts: [], cRole: '', cParticipant: null, cAccountIndex: null, uAccounts: null, toggle: null }
// uAccounts is a json that has arrays of account_name, account_subdomain, account_id, account_role

export const useAuthStore = defineStore('auth.store', {
  state: (): IState => ({ ...initialState }),
  getters: {
    toggleDashboard: (state: IState) => state.toggle,
    currentUser: (state: IState) => state.user,
    currentRole: (state: IState) => state.cRole,
    currentParticipant: (state: IState) => state.cParticipant,
    currentAccountIndex: (state: IState) => state.cAccountIndex,
    userAccounts: (state: IState) => state.accounts,
    isAuthenticated: (state: IState) => !!state.user,
    userRoles: (state: IState) => state.roles.map((role) => role.name),
    userAccountsList: (state: IState) => state.uAccounts,
    // currentLayout: (state: IState) => state.layout, // TODO: move to another store?
    isTOU: (state: IState) => !!state.user.TOU
  },
  actions: {
    setToggleDashboard(toggleValue) {
      this.toggle = toggleValue
    },

    setCurrentRole(newRole) {
      this.cRole = newRole
    },

    setCurrentParticipant(participant) {
      this.cParticipant = participant
    },

    setCurrentAccountIndex(account_index){
      this.cAccountIndex = account_index
    },

    login(user: IUserLogin) {
      return AuthService.login(user).then(
        (response: AxiosResponse) => {
          this.user = response
          // CJS - login/logout test - this gets done in auth.service.ts/login
          //AuthService.setUser(this.user)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    },
    logout() {
      AuthService.logout().then(() => {
        this.user = null
        location.href = rootUrl()
      })
    },
    signup(user: IRegisterUser) {
      return AuthService.signup(user)
    },
    register(user: IRegisterUser) {
      return AuthService.register(user)
    },
    resetPassword(email) {
      AuthService.resetPassword(email)
    },

    // TODO: ensure this doesn't conflict with setting user in login action (above)
    fetchCurrentUser() {
      return AuthService.fetchCurrentUser().then(
        (response: AxiosResponse) => {
          this.user = response.data
          this.roles = response.data.current_roles
          this.accounts = response.data.accounts
          // CJS - login/logout test -- this get set in auth.sevice.ts
          //AuthService.setUser(this.user)
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    },

    // TODO: ensure this doesn't conflict with setting user in other actions (above)
    fetchInvitedUser(invitationToken: string) {
      return AuthService.fetchInvitedUser(invitationToken).then(
        (response: AxiosResponse) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    },

    // TODO: ensure this doesn't conflict with setting user in other actions (above)
    fetchResetUser(resetToken: string) {
      return AuthService.fetchResetUser(resetToken).then(
        (response: AxiosResponse) => {
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    },

    updatePassword(user: IRegisterUser) {
      return AuthService.updatePassword(user)
    },

    getUserAccounts() {
      return AuthService.getUserAccounts().then(
        (response: AxiosResponse) => {
          this.uAccounts = response.data
          return Promise.resolve(response)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
